import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F8F7F4',
      neutralLight3: '#F2F0EA',
      neutralDark1: '#000000',
      primaryDark: '#292524',
      primaryLight: '#E3DED0',
      primaryLight2: '#A29D9C',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'scotch-display', sans-serif",
    label: "'adobe-caslon-pro', serif",
    paragraph: "'proxima-nova', sans-serif",
  },
}

export const japanese = {
  ...theme,
  fontFamily: {
    heading: "'source-han-serif-jp-subset', sans-serif",
    label: "'source-han-serif-jp-subset', sans-serif",
    paragraph: "'source-han-serif-jp-subset', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.primaryDark};
  font-family: ${theme.fontFamily.heading};
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.label};
    font-size: 0.8125rem;
    letter-spacing: 0.2em;
    line-height: 1rem;
    margin: 0 auto 2.25rem;
    text-transform: uppercase;

    @media (max-width: 1199px) {
      font-size: 0.6875rem;
      margin-bottom: 1.5rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 6rem;
      line-height: 7.1875rem;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.6875rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.5rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.125rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.625rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.125rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.6875rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.125rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 1.75rem;
      line-height: 2.125rem;

      @media (max-width: 1199px) {
        font-size: 1.375rem;
        line-height: 1.6875rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 2.125rem;

      @media (max-width: 1199px) {
        font-size: 1.375rem;
        line-height: 1.875rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.75rem;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    min-width: 20rem;
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.primaryLight};
    box-shadow: ${rgba(theme.colors.variants.primaryDark, 0.1)} 0 0 1.25rem;
    color: ${theme.colors.variants.primaryDark};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight5, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day {
    border: 0.0625rem solid ${rgba(theme.colors.variants.neutralLight5, 0.3)};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-color: ${theme.colors.variants.primaryLight};
    background-color: ${theme.colors.variants.primaryLight} !important;
    color: ${theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.variants.primaryDark};
    color: ${theme.colors.variants.neutralLight5};
  }

  .react-datepicker__day:focus {
    background-color: ${theme.colors.variants.primaryDark} !important;
    color: ${theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 2.625rem;
      background-color: transparent;
      background-image: url('/svg/calendar.svg');
      background-repeat: no-repeat;
      background-position: right center;
      border-bottom: 0.125rem solid ${theme.colors.variants.primaryLight};
      border-radius: 0;
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      font-weight: 300;
      color: ${theme.colors.variants.primaryDark};
      line-height: 1.75rem;
      padding: 0;
      &.error {
        border-bottom-color: ${theme.colors.variants.danger};
      }

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.2;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.2;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.2;
      }
      &::placeholder {
        color: ${theme.colors.variants.primaryDark};
        opacity: 0.2;
      }
    }
  }
`

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  pageContext,
}: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  let t

  switch (pageContext.languagePrefix) {
    case 'ja':
      t = japanese
      break

    default:
      t = theme
  }

  return (
    <EmotionThemeProvider theme={t}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
